import {
  booleanAttribute,
  Component,
  ContentChild,
  Directive, ElementRef,
  EventEmitter,
  HostListener,
  Input, OnChanges, OnInit,
  Output, SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { RegexpSearch } from '../../../shared/helpers/regex';
import { mod } from '../../../shared/helpers/math';

export interface DropdownItem<T = any> extends DisplayItem<T> {
  description?: string;
  icon?: string;
  disabled?: boolean;
  meta?: object;
}

export interface DisplayItem<T> {
  label: string;
  value: T;
}

@Directive({
  selector: '[exDropdownFooter]'
})
export class ExDropdownFooterDirective {
  constructor(public template: TemplateRef<any>) {
  }
}

@Directive({
  selector: '[exDropdownContent]'
})
export class ExDropdownContentDirective {
  constructor(public template: TemplateRef<any>) {
  }
}

@Directive({
  selector: '[exDropdownItem]'
})
export class ExDropdownItemDirective {
  constructor(public template: TemplateRef<{ $implicit: DropdownItem }>) {
  }
}

@Directive({
  selector: '[exDropdownDisplay]'
})
export class ExDropdownDisplayDirective {
  constructor(public template: TemplateRef<{ $implicit: string }>) {
  }
}

@Component({
  selector: 'ex-dropdown',
  templateUrl: './ex-dropdown.component.html',
  styleUrls: ['./ex-dropdown.component.scss']
})
export class ExDropdownComponent implements OnChanges, OnInit {

  @ContentChild(ExDropdownContentDirective) contentTemplate?: ExDropdownContentDirective;
  @ContentChild(ExDropdownItemDirective) itemTemplate?: ExDropdownItemDirective;
  @ContentChild(ExDropdownFooterDirective) footerTemplate?: ExDropdownFooterDirective;
  @ContentChild(ExDropdownDisplayDirective) displayTemplate?: ExDropdownDisplayDirective;

  @Input() icon?: string;
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input({transform: booleanAttribute}) mustSelect?: boolean;
  @Input({transform: booleanAttribute}) multiple?: boolean;
  @Input({transform: booleanAttribute}) noInput?: boolean;
  @Input({transform: booleanAttribute}) strictWidth?: boolean;
  @Input({transform: booleanAttribute}) disabled?: boolean;
  @Input({transform: booleanAttribute}) replaceChevron?: boolean;
  @Input({transform: booleanAttribute}) noChevron?: boolean;
  @Input({transform: booleanAttribute}) blackStyle?: boolean;
  @Input({transform: booleanAttribute}) boldInputText?: boolean;
  @Input({transform: booleanAttribute}) noBorder?: boolean;
  @Input({transform: booleanAttribute}) ignoreEnterKey?: boolean;
  @Input() inputText?: string;
  @Input() defaultValues?: string[];
  @Input() flatSide?: 'left' | 'right';
  @Input() size: 'x-small' | 'small' | 'medium' = 'medium';
  @Input() target: 'container' | 'body' | 'none' = 'container';
  @Input() headerColor?: string = '';
  @Input() headerBackgroundColor?: string = '';
  @Input() chevronVisibility: 'never' | 'always' | 'hover' = 'always';
  @Input() items?: DropdownItem[];
  @Input() forceItem$?: EventEmitter<DropdownItem | null | undefined>;

  @Input() values?: string[];
  @Input() forceToggle$?: EventEmitter<boolean>;
  @Output() valuesChange = new EventEmitter<string[] | undefined>();

  @Output() change = new EventEmitter<DropdownItem | null>();
  @Output() dropdownToggle = new EventEmitter<boolean>();
  @Output() outsideClicked = new EventEmitter<boolean>();

  text: string = "";
  selection?: DropdownItem | DropdownItem[];
  show = false;
  filteredItems?: DropdownItem[];

  @ViewChild('input') input?: ElementRef;
  @ViewChild('itemsContainer') itemsContainer?: ElementRef;
  focusIndex?: number;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inputText'] && this.inputText) {
      this.text = this.inputText;
    }
    if (changes['defaultValues']) {
      this.selection = this.items?.filter(item => this.defaultValues?.includes(item.value.toString()));
      if (!this.inputText) {
        this.text = this.selection?.map(item => item.label).join(', ') ?? '';
        this.update()
      }
    }
    if (changes['values']) {
      this.selection = this.items?.filter(item => this.values?.includes(item.value.toString()));
      if (!this.inputText) {
        this.text = this.selection?.map(item => item.label).join(', ') ?? '';
        this.update()
      }
    }
    if (changes['forceToggle$'] && this.forceToggle$) {
      this.forceToggle$.subscribe((show) => this.toggle(show));
    }
  }

  ngOnInit() {
    // ce forceItem permet de gérer des cas où la valeur de item change depuis le composant parent
    // mais sans notifier le composant ex-dropdown.
    this.forceItem$?.subscribe((item) => {
      this.select(item ?? null, !item);
    });
  }

  toggle(show?: boolean) {
    const newState = show ?? (!this.show || this.input?.nativeElement === document.activeElement);
    if (this.show !== newState) this.dropdownToggle.emit(newState);
    this.show = newState;
    if (this.show) {
      // focus text input field when opening dropdown with input.
      if (this.elementRef.nativeElement.querySelector('input[type="text"]')) {
        setTimeout(() => this.elementRef.nativeElement.querySelector('input[type="text"]')?.focus(), 50);
      }
      this.update();
    }
  }

  update(value?: string) {
    const newValue = value?.trim() || this.text.trim();
    if (newValue) {
      if(this.selection && !(this.selection instanceof Array) && newValue === this.selection.label.trim())
        this.filteredItems = this.items;
      else this.filteredItems = this.items?.filter(item => RegexpSearch.normalizedSearch(
        item.label,
        newValue,
      ));
    } else {
      this.select(null)
      this.filteredItems = this.items;
    }
    this.focusIndex = 0;
  }

  select(item: DropdownItem | null, propagate = true) {
    if (this.selection == item) return;
    this.selection = item || undefined;
    this.values = this.selection?.value;
    this.valuesChange.emit(this.values);
    this.text = item?.label || '';
    this.focusIndex = undefined;
    if (propagate) this.toggle(false);
    if (propagate) this.update();
    if (propagate) this.change.emit(item);
  }

  clear() {
    this.text = '';
    this.select(null);
  }

  isDisabled(item: DropdownItem) {
    return item.disabled ||
      !!(this.selection && (
        (this.selection instanceof Array && this.selection.includes(item)) ||
        (!(this.selection instanceof Array) && this.selection.value === item.value))
      )
      ;
  }

  @HostListener('document:keydown.ArrowUp', ['$event'])
  @HostListener('document:keydown.ArrowDown', ['$event'])
  onArrow(event: KeyboardEvent) {
    if (!this.filteredItems) {
      return;
    }
    event.preventDefault();
    if (!this.show && this.input?.nativeElement === document.activeElement)
      return this.toggle(true);
    if (this.focusIndex !== undefined) {
      this.focusIndex =
        mod(this.focusIndex + (event.key === 'ArrowUp' ? -1 : 1), this.filteredItems.length);
      this.itemsContainer?.nativeElement.children[this.focusIndex]?.scrollIntoView({block: 'nearest'});
    } else {
      this.focusIndex = (event.key === 'ArrowUp' ? (this.filteredItems.length) - 1 : 0);
    }
  }

  clickOutside() {
    this.outsideClicked.emit(true);
    this.toggle(false);
  }

  @HostListener('document:keydown.Enter', ['$event'])
  @HostListener('document:keydown.Tab', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (this.show && !this.ignoreEnterKey) {
      event.preventDefault();
      if (this.focusIndex !== undefined) {
        this.select(this.filteredItems?.[this.focusIndex] || null);
      }
    }
  }

  @HostListener('document:keydown.Escape', ['$event'])
  onEscape(event: KeyboardEvent) {
    if (event.target instanceof Element && event.target.tagName === 'INPUT') {
      event.preventDefault();
      this.toggle(false);
    }
  }

  @HostListener('mousemove')
  onMouseMove() {
    this.focusIndex = undefined;
  }
}

